import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HashLink } from "react-router-hash-link";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import MobileFooterSticky from "./MobileFooterSticky";

import {
  Container,
  Button,
  Link,
  lightColors,
  darkColors,
} from "react-floating-action-button";

import Image1 from "images/shalom/hijo-de-dios-nino-mio/1.jpg";
import Image2 from "images/shalom/hijo-de-dios-nino-mio/2.jpg";
import Image3 from "images/shalom/hijo-de-dios-nino-mio/3.jpg";
import Image4 from "images/shalom/hijo-de-dios-nino-mio/4.jpg";
import Image5 from "images/shalom/hijo-de-dios-nino-mio/5.jpg";
import Image6 from "images/shalom/hijo-de-dios-nino-mio/6.jpg";
import Image7 from "images/shalom/hijo-de-dios-nino-mio/7.jpeg";
import Image8 from "images/shalom/hijo-de-dios-nino-mio/8.png";
// import Image7 from "images/morat/7.jpeg";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  Image8,
  Image3,
  Image1,
  Image2,
  Image4,
  Image5,
  Image6,
  //   Image7,
  //   "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  //   "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge name="Wooden house" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Hijo de Dios, Niño Mío
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating />
          <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1" style={{ fontWeight: 600 }}>
              18 de Diciembre 2022 - Lima, Perú
            </span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar imgUrl="https://s3.amazonaws.com/assets-beta.eticket.pe/promoters/comunidad-catolica-shalom-peru.png" hasChecked={false} sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Evento organizado por{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              COMUNIDAD CATÓLICA SHALOM - PERÚ
            </span>
          </span>
        </div> */}

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          Hijo de Dios, Niño Mío - Comunidad Católica SHALOM
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            "Hijo de Dios, Niño Mío" es un espectáculo musical inspirado en el
            arte circense, nace de una adaptación del libro de la cofundadora de
            la Comunidad Católica Shalom, Maria Emmir Nogueira. Su diferencial y
            sorpresa se basa principalmente por el guión, el vestuario y la
            partitura musical.
          </span>
          <br />
          <br />
          <span>
            De manera lúdica, divertida y dinámica, se presenta, desde la
            perspectiva de la "Troupe de la Encarnación", los eventos más
            importantes que se dieron desde el nacimiento de María hasta el
            nacimiento de Jesús.
          </span>
          <br />
          <br />
          <span>
            En escena se podrán apreciar varias expresiones musciales. La
            "Troupe de la Encarnación" es una compañía de circo que acompaña en
            "tiempo real" todos esos acontecimientos, no apenas narrando, sino
            invitando a que el público participe e introduciendo a cada uno en
            el misterio que se pasa. El público es llevado a experimentar la
            ternura en escenas como "El voto de María", "El matrimonio de María
            y José", de lo cómico en "Caminos de Belén".
          </span>
          <br />
          <br />
          <span>
            Realizado en Brasil desde hace más de 10 años, fue adaptado a
            diversas realidades en los demás países de Latinoamérica, tales como
            Chile, Uruguay, Argentina, Bolivia.
          </span>
          <br />
          <br />
          <span>
            Los actores son voluntarios, en su mayoría miembros de nuestra
            Comunidad y grupos de oración. Pasando por un casting donde se
            invita abiertamente a todos los que quieran audicionar para esta
            obra.
          </span>
          <br />
          <br />
          <span>El elenco actual cuenta con 23 actores.</span>
          {/* <br />
          <br />
          <span>
            El evento contará con la invitada especial Camila Cabello, no te lo
            puedes perder!
          </span> */}
          {/* <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span> */}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Sectores</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Los precios incluyen IGV.
          </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>General</span>
              <span>S/ 20.00</span>
              <span>700 entradas x función</span>
            </div>
            {/* <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Vip</span>
              <span>S/ 380.00</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>
                <s>Preferencial</s>
              </span>
              <span>
                <s>S/ 270.00</s>
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>
                <s>Tribuna</s>
              </span>
              <span>
                <s>S/ 160.00</s>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionPrices = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Funciones Disponibles</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Los precios incluyen IGV.
          </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span className="font-semibold">
                domingo, 18 de diciembre 2022
              </span>
              <span>
                <ButtonPrimary
                  href={"https://buy.stripe.com/00g2bQ0Bh53t3WU001"}
                  targetBlank
                >
                  4:00 PM
                </ButtonPrimary>
              </span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span className="font-semibold">
                Domingo, 18 de diciembre 2022
              </span>
              <span>
                <ButtonPrimary
                  href={"https://buy.stripe.com/6oE2bQ0BheE3fFC7su"}
                  targetBlank
                >
                  7:00 PM
                </ButtonPrimary>
              </span>
            </div>
            {/* <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Vip</span>
              <span>S/ 380.00</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>
                <s>Preferencial</s>
              </span>
              <span>
                <s>S/ 270.00</s>
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>
                <s>Tribuna</s>
              </span>
              <span>
                <s>S/ 160.00</s>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => setSelectedDate(date)}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel={false}
              isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSectionPromoter = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Organizador del Evento</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked={false}
            imgUrl="https://s3.amazonaws.com/assets-beta.eticket.pe/promoters/comunidad-catolica-shalom-peru.png"
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="https://comshalom.org/" target={"_blank"} rel="noreferrer noopener">
              COMUNIDAD CATÓLICA SHALOM - PERÚ
            </a>
            {/* <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div> */}
          </div>
        </div>

        {/* desc */}
        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
        RUC: 20606077662 
        </span> */}

        {/* info */}
        {/* <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div> */}

        {/* == */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div> */}
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}

        <h2 className="text-2xl font-semibold">Proceso de compra</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        {/* <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div> */}

        <span className="block text-neutral-6000 dark:text-neutral-300">
          Para este evento tenemos 2 funciones
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="font-semibold">
              <HashLink
                className="font-semibold"
                // target="_blank"
                rel="noreferrer noopener"
                to="/hijo-de-dios-nino-mio-comunidad-catolica-shalom-entradas#funciones"
              >
                Domingo, 18 de Diciembre del 2022, 4:00 PM
              </HashLink>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="font-semibold">
              <HashLink
                className="font-semibold"
                // target="_blank"
                rel="noreferrer noopener"
                to="/hijo-de-dios-nino-mio-comunidad-catolica-shalom-entradas#funciones"
              >
                Domingo, 18 de Diciembre del 2022, 7:00 PM
              </HashLink>
            </span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div> */}
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          En la sección{" "}
          <HashLink
            className="font-semibold"
            // target="_blank"
            rel="noreferrer noopener"
            to="/hijo-de-dios-nino-mio-comunidad-catolica-shalom-entradas#funciones"
          >
            Funciones Disponibles
          </HashLink>
          , escoja el horario que desea, a continuación se le redireccionará a
          la página de pago, donde podrá seleccionar la cantidad de entradas que
          desea adquirir (máx. 10 entradas por compra).
        </span>

        <span className="block text-neutral-6000 dark:text-neutral-300">
          Luego, ingrese su{" "}
          <span className="font-semibold">dirección de correo electrónico</span>{" "}
          y <span className="font-semibold">número de celular</span>,{" "}
          <span className="font-semibold">
            asegúrese de ingresar correctamente estos datos
          </span>
          ,{" "}
          <span className="font-semibold">
            especialmente su dirección de correo electrónico, ya que a ese
            correo se enviará la información de sus entradas
          </span>
          .
        </span>

        <span className="block text-neutral-6000 dark:text-neutral-300">
          Continue ingresando la información de pago, lea y acepte los términos
          y condiciones de servicio, y realice el pago. Una vez su pago sea
          confirmado,{" "}
          <span className="font-semibold">
            recibirá un correo electrónico con la información de sus entradas.
          </span>
        </span>

        <span className="block text-neutral-6000 dark:text-neutral-300">
          El día del evento, asista a la hora indicada y presente su entrada
          impresa o en su celular, esta contiene un{" "}
          <b>código QR único por entrada</b>, por favor no comparta esta
          información con nadie, quien tenga acceso a su entrada, podrá acceder
          el día del evento y sólo podrá ser usada una vez
        </span>

        {/* info */}
        {false && (
          <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
            <div className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <span className="font-semibold">
                Jueves, 01 de Diciembre del 2022, 21:00hrs
              </span>
            </div>
            {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div> */}
          </div>
        )}

        <span className="block text-neutral-6000 dark:text-neutral-300">
          Si tiene alguna duda, contáctenos mediante el botón de Chat ubicado en
          la parte inferior derecha de su pantalla. Tambien puede escribirnos a
          nuestro{" "}
          <a
            className="font-semibold"
            target="_blank"
            rel="noreferrer noopener"
            href="https://wa.me/+51993312156"
          >
            WhatsApp
          </a>{" "}
          al número{" "}
          <a
            className="font-semibold"
            target="_blank"
            rel="noreferrer noopener"
            href="https://wa.me/+51993312156"
          >
            +51 993 312 156
          </a>
        </span>

        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
          3. Deberá hacer el pago a la cuenta bancaria a nombre{" "}
          <b>ETICKET PERU E.I.R.L - RUC 20609738970</b>
        </span> */}

        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
          4. Una vez realizada la transferencia bancaria, deberá enviarnos el
          comprobante de pago y procederemos a validar el pago.
        </span> */}

        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
          5. Una vez validada la transferencia, procederemos a enviarle sus
          entradas y el comprobante de pago al correo electrónico que nos
          proporcione, desde el correo <b>no-reply@eticket.pe</b>
        </span> */}

        {/* == */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div> */}
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Ubicación</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Av. Gral. Recavarren 890, Surquillo, Lima, Perú (Auditorio del
            Colegio San Vicente de Paul)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA9eT75y7YJ4iuBacyw4Jt3-x8eNVVL9_I",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={17}
              defaultCenter={{
                lat: -12.11266,
                lng: -77.025322,
              }}
            >
              <LocationMarker lat={-12.11266} lng={-77.025322} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          Términos y Condiciones de Compra
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">El evento</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            eTicket es una empresa que se dedica a la venta y distribución de
            entradas físicas y/o digitales, para eventos deportivos, culturales,
            musicales, entre otros. eTicket no es el organizador del evento, por
            lo que no se hace responsable por la cancelación o reprogramación
            del evento, ni por la calidad del servicio brindado por el
            organizador del evento.
          </span>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            En caso de devoluciones, estas serán atendidas directamente con el{" "}
            <b>ORGANIZADOR DEL EVENTO</b>.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">Las entradas</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Dada la naturaleza del evento, las entradas son digitales,
            (E-TICKET). Una vez realizada la compra, el comprador recibirá un
            correo, adjuntado las entradas digitales (E-TICKET) en formato PDF
            junto con su comprobante de venta, emitido por eTicket - ETICKET
            PERU E.I.R.L.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Reembolsos</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Dada la naturaleza de las entradas, E-TICKETS, los reembolsos serán
            atendidos directamente con el <b>ORGANIZADOR DEL EVENTO</b>, eTicket
            orientará al comprador sobre el procedimiento a seguir para la
            devolución de su dinero.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          <h4 className="text-lg font-semibold">Responsabilidad</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            eTicket no se hace responsable por la pérdida o robo de las entradas
            digitales (E-TICKET) una vez recibidas por el comprador.
          </span>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Dada la naturaleza de las entradas, E-TICKETS, una vez recibidas las
            entradas, el cliente es responsable de salvaguardar sus entradas.
            eTicket - ETICKET PERU E.I.R.L., garantiza que las entregas sean
            originales, y garantiza el uso de las entradas en el evento, pero no
            se hace responsable por la pérdida o robo de las mismas una vez
            hayan sido recibidas por el cliente.
          </span>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        {/* <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $119
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <StartRating />
        </div> */}

        {/* FORM */}
        {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => setSelectedDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
            hasButtonSubmit={false}
          />
        </form> */}

        {/* SUM */}
        {/* <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$119 x 3 night</span>
            <span>$357</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div> */}

        {/* SUBMIT */}
        <ButtonPrimary
          href={
            "/hijo-de-dios-nino-mio-comunidad-catolica-shalom-entradas#proceso"
          }
          hash
        >
          Empezar compra!
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      <Helmet>
        <title>
          Compra entradas para Hijo de Dios, Niño Mío dom, 18 dic 2022,
          Auditorio del Colegio San Vicente de Paul en Lima | eTicket
        </title>
        <meta
          name="description"
          content="Compra entradas para Hijo de Dios, Niño Mío dom, 18 dic
          2022, Auditorio del Colegio San Vicente de Paul en Lima | eTicket"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_CUSTOM_PUBLIC_URL}/hijo-de-dios-nino-mio-comunidad-catolica-shalom-entradas`}
        />
        {/* <meta property="og:type" content="website" /> */}
        <meta
          property="og:title"
          content="Compra entradas para Hijo de Dios, Niño Mío dom, 18 dic
          2022, Auditorio del Colegio San Vicente de Paul en Lima | eTicket"
        />
        <meta
          property="og:description"
          content="Compra entradas para Hijo de Dios, Niño Mío dom, 18 dic
          2022, Auditorio del Colegio San Vicente de Paul en Lima | eTicket"
        />

        <meta
          property="og:image"
          content="https://s3.amazonaws.com/assets-beta.eticket.pe/posters/hijo-de-dios-nino-mio-shalom.png"
        />
      </Helmet>
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Ver todas las fotos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection7()}
          {/* {renderSection3()} */}
          <div id="proceso"></div>
          {/* {renderSectionCheckIndate()} */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {renderSection4()}
          <div id="funciones"></div>
          {renderSectionPrices()}
          {renderSectionPromoter()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && <MobileFooterSticky />}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingStayDetailPage1"}
            />
          </div> */}

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
      {/* <Container>
        <Link href="#" tooltip="Create note link" icon="far fa-sticky-note" />
        <Link
          href="#"
          tooltip="Add user link"
          icon="fas fa-user-plus"
          className="fab-item btn btn-link btn-lg text-white"
        />
        <Button
          tooltip="The big plus button!"
          icon="fas fa-plus"
          styles={{
            backgroundColor: darkColors.lighterRed,
            color: lightColors.white,
          }}
          rotate={true}
          onClick={() => alert("FAB Rocks!")}
        />
      </Container> */}
    </div>
  );
};

export default ListingStayDetailPage;
